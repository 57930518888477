$clr-accent: #03a9f4;
$clr-text: #000000de;
$clr-bg: #ffffff;
$clr-border: #dddddd;
$clr-success: #5cb85c;
$clr-warning: #f0ad4e;
$clr-danger: #d9534f;

$clr-primary: #03a9f4;
$clr-secondary: #1a2e38;
$clr-white: #ffffff;
$clr-black: rgba(0, 0, 0, 0.87);
$clr-gray: #696969;

$clr-tab-bg: #f7f7f7;

.bg-blue {
    background-color: #03a9f4;
}

.bg-success {
    background-color: $clr-success;
}

.bg-danger {
    background-color: $clr-danger;
}
.bg-secondary {
    background-color: $clr-secondary;
}

.bg-gray {
    background-color: $clr-gray;
}

.text-gray {
    color: $clr-gray;
}

.bg-accent {
    background-color: $clr-accent;
}

.cl-accent {
    color: $clr-accent !important;
}
.cl-gray {
    color: $clr-gray !important;
}
.cl-success {
    color: $clr-success !important;
}
.cl-warning {
    color: $clr-warning !important;
}
.cl-danger {
    color: $clr-danger !important;
}